@font-face {
  font-family: "RedHatDisplay";
  src: url("./RedHatDisplay/RedHatDisplayVF.woff2") format("woff2-variations");
  font-weight: 300 900;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "RedHatDisplay";
  src: url("./RedHatDisplay/RedHatDisplayVF-Italic.woff2")
    format("woff2-variations");
  font-weight: 300 900;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: "RedHatText";
  src: url("./RedHatText/RedHatTextVF.woff2") format("woff2-variations");
  font-weight: 300 700;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "RedHatText";
  src: url("./RedHatText/RedHatTextVF-Italic.woff2") format("woff2-variations");
  font-weight: 300 700;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: "RedHatMono";
  src: url("./RedHatMono/RedHatMonoVF.woff2") format("woff2-variations");
  font-weight: 300 700;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "RedHatMono";
  src: url("./RedHatMono/RedHatMonoVF-Italic.woff2") format("woff2-variations");
  font-weight: 300 700;
  font-style: italic;
  font-display: fallback;
}

pre,
code {
  font-family: "RedHatMono", "Liberation Mono", consolas, "SFMono-Regular",
    menlo, monaco, "Courier New", monospace;
}
